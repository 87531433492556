<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6673 3.02559V8.53235C18.6673 9.279 18.6673 9.65232 18.8126 9.9375C18.9405 10.1884 19.1444 10.3923 19.3953 10.5201C19.6805 10.6654 20.0539 10.6654 20.8007 10.6654H26.308M12.0007 21.3308L14.6673 23.9971L20.6673 17.9978M18.6673 2.66626H11.734C9.49377 2.66626 8.37367 2.66626 7.51802 3.10218C6.76537 3.48563 6.15345 4.09748 5.76996 4.85005C5.33398 5.70559 5.33398 6.82557 5.33398 9.06552V22.9306C5.33398 25.1705 5.33398 26.2905 5.76996 27.1461C6.15345 27.8986 6.76537 28.5105 7.51802 28.8939C8.37367 29.3299 9.49377 29.3299 11.734 29.3299H20.2673C22.5075 29.3299 23.6276 29.3299 24.4833 28.8939C25.2359 28.5105 25.8478 27.8986 26.2313 27.1461C26.6673 26.2905 26.6673 25.1705 26.6673 22.9306V10.6653L18.6673 2.66626Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconFileCheck"
}
</script>
